@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400&display=swap');

:root {
  --color-primary: #023E8A;
  --color-primary-dark: #03045E;
  --dark-button-background-color: #141D26;
}

html, body {
  background: linear-gradient(180deg, var(--color-primary) 0%, var(--color-primary-dark) 105.08%);
  overflow-x: hidden;
  height: 100%;
}

* {
  font-family: 'Sora', sans-serif;
}

body {
  margin: 0;
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
