.App {
    background: url("./res/pattern.png") no-repeat center 100px;
}

.App-content {
    padding: 20px 10% 30px;
}

.App-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.App-logo {
    height: 64px;
    pointer-events: none;
}

.App-title {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.App-title h1 {
    font-style: normal;
    font-weight: bolder;
    font-size: 5em;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
}

.Request-beta-form {
    display: flex;
    justify-content: center;
}

.Request-beta-form input[type=email] {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    border: none;
    padding: 18px;
    width: 60vw;
    max-width: 300px;
    margin: 0 10px;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 500;
    font-size: 14px;
}

.Request-beta-form button, .downloadBtn {
    background: var(--dark-button-background-color);
    border-radius: 8px;
    border: none;
    padding: 15px 20px;
    margin: 0 10px;
    color: white;
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
}

.Request-beta-form button:disabled {
    background: rgba(49, 56, 66, 0.68);
    color: #a9a9a9;
    cursor: not-allowed;
}

.message-text {
    color: white;
    padding: 10px 0;
    margin-top: 30px;
    width: 100%;
    text-align: center;
}

.App-showcase {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.App-showcase-image {
    width: 50vw;
}

.App-how-it-works {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
}

.App-how-it-works h1 {
    font-style: normal;
    font-weight: bolder;
    font-size: 3em;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
}

.App-how-it-works-steps {
    display: flex;
    margin-top: 50px;
    padding: 0 10%;
    border-top: 3px solid #6096BA;
}

.App-how-it-works-steps div.item {
    width: 100%;
    padding: 0 40px;
}

.App-how-it-works-steps div div:first-child {
    margin-top: -30px;
    background: #6096BA;
    border-radius: 64px;
    width: min-content;
    max-width: min-content;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-size: 1.2em;
    padding: 0 25px;
    font-weight: 400;
}

.App-how-it-works-steps div div:last-child {
    color: #FFFFFF;
    margin-top: 60px;
    font-weight: 400;
    font-size: 1.3em;
    line-height: 36px;
    letter-spacing: 1px;
}

.App-footer {
    padding: 40px 10%;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
}

.App-footer-links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

a {
    color: #FFFFFF;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-self: center;
}

.App-footer-links-multiple {
    display: flex;
    flex-direction: row;
}

.App-footer-links-multiple a {
    margin-right: 15px;
}

.App-Footer-copyright {
    color: #FFFFFF;
    margin-top: 70px;
    margin-bottom: 40px;
}

.App-watch-how-it-works {
    margin-top: 120px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.App-watch-how-it-works button {
    background: #141D26;
    border-radius: 8px;
    color: white;
    padding: 15px 20px;
    text-align: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: max-content;
}

.App-watch-how-it-works button img {
    margin-right: 10px;
}

.App-watch-how-it-works video {
    width: 40vw;
    margin-top: 10px;
    border-radius: 10px;
}

@media (min-width: 768px) {
    .App-title {
        margin-top: 20px;
    }
}

@media (max-width: 840px) {
    .App {
        background: url("./res/pattern.png") no-repeat center 150px;
        background-size: 130vw;
    }

    .App-watch-how-it-works {
        margin-top: 20px;
    }

    .App-watch-how-it-works video {
        width: 80vw;
    }
}

@media (max-width: 767.98px) {
    .App-logo {
        height: 50px;
    }

    .App-content {
        padding: 10px 7% 30px;
    }

    .App-title {
        margin-top: 10px;
    }

    .App-title h1 {
        font-size: 8vw;
        line-height: initial;
    }

    .App-showcase {
        margin-top: 40px;
    }

    .App-showcase-image {
        width: 90vw;
    }

    .Request-beta-form {
        flex-direction: column;
        width: 100%;
    }

    .Request-beta-form label {
        display: flex;
    }

    .Request-beta-form input {
        width: 100% !important;
    }

    .Request-beta-form button {
        margin-top: 20px;
    }

    .App-how-it-works {
        padding-top: 20px;
        margin-bottom: 0;
    }

    .App-how-it-works h1 {
        font-size: 1.6em;
    }

    .App-how-it-works-steps {
        border: none;
        flex-direction: column;
    }

    .App-how-it-works-steps {
        margin-top: 5px;
    }

    .App-how-it-works-steps div.item {
        padding: 0;
    }

    .App-how-it-works-steps div div:first-child {
        margin-top: 0;
        min-width: 56px;
        height: 35px;
        padding: 0;
    }

    .App-how-it-works-steps div div:last-child {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 1em;
        line-height: 25px;
    }

    .App-footer {
        padding: 10px 10%;
        margin-top: 10px;
    }

    .App-footer-links {
        flex-direction: column;
        justify-content: start;
        width: max-content;
    }

    .App-footer-links div a {
        justify-content: start;
        align-items: start;
        margin-top: 30px;
    }

    a {
        font-size: .8em;
    }

    .App-Footer-copyright {
        margin-top: 35px;
    }
}

@media (min-width: 576px) {
    .App-title h1 {
        font-size: 4em;
    }
}

.downloadBtn {
    width: 250px;
    margin-top: 30px;
    text-align: center;
    display: flex;
    align-items: center;
}

.downloadBtn img {
    width: 20px;
    margin-left: 10px;
}

.App.BetaDownload {
    background: white;
    height: 100vh;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    color: #023E8A;
    font-weight: 800;
}